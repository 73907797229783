.sign-in {
    &.container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    &.form {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        width: 450px;
    }

    &.button {
        background: blue;
        color: white;
        width: 125px;

        &.forgot-password {
            margin-right: 20px;
        }

        &.cancel {
            width: 100px;
            color: white;
            background: grey;
        }
    }

}
