.Profile {
    cursor: pointer;

    &.container {
        display: flex; 
        width: 300px;
        height: 55px;

        .image-container {
            width: 50%;
            height: 100%;
            text-align: right;
            padding-right: 15px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .name-container {
            width: 50%;
        }
    }

    &.popover {
        box-shadow: none;
        border: none;
        width: 100px;
        color: blue;
        width: 125px;
        text-align: left;
    }
}

.avatar{
    .avatar {
        vertical-align: middle;
        width: 150px;
        height: 50px;
        border-radius: 50%;
      }
}

.popover {
    top: 15px;

    .popover-body {
        
        &:hover {
            color: #007bff !important;
        }
    }
}