$app-background-color: #F2F5FD;
$sidebar-background-color: #6F6F6F;
$sidebar-background-color-active: #858585;
$sidebar-border: #8B8B8B;
$navbar-background: #6F6F6F;
$navbar-background-active: #858585;
$navbar-dropdown: #646464;
$grid-border-color: #DCE3EA;

$alert-background: #dc3545;
$success-background: #28a745;

// Links

$link-color-light: #ffffff;
$link-color-dark: #007BFF;

$font-color-dark: #333333;
$font-color-light: #ffffff;
$breadcrumb-color: #8D8D8D;

// Breakpoints

$break-xs-down: "max-width: 0px";
$break-sm-down: "max-width: 575px";
$break-md-down: "max-width: 767px";
$break-lg-down: "max-width: 991px";
$break-xl-down: "max-width: 1199px";

$break-sm-up: "min-width: 576px";
$break-md-up: "min-width: 768px";
$break-lg-up: "min-width: 992px";
$break-xl-up: "min-width: 1200px";
